import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Logo from '../images/plexnoir_logo.inline.svg';

const Header = ({ siteTitle }) => (
  <header
    style={{
      marginBottom: `3.45rem`,
    }}
  >
    <div
      style={{
        margin: `0 auto`,
        maxWidth: 960,
        padding: `1.45rem 1.0875rem`,
      }}
    >
      <Logo className={'logo'} />
      <h1 style={{ margin: 0, marginTop: '0.5rem' }}>
        <Link
          to="/"
          style={{
            color: `white`,
            textDecoration: `none`,
          }}
        >
          {siteTitle}
        </Link>
      </h1>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
